import {
    createStyles,
    Text,
    Container,
    Group,
    Pagination,
    Button,
    SimpleGrid,
    Divider,
    Select,
    Center,

} from '@mantine/core';

import { useEffect, useState } from 'react';
import config from '../../const/config';
import StoreCard from './StoreCard';
import axios from 'axios';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: 80,

    },


    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
        paddingTop: 80,

    },

    cta: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 140,
        backgroundColor: "white",
    },

    title: {
        textAlign: 'center',
        fontWeight: 800,
        fontSize: 52,
        letterSpacing: -1,
        color: "#325170F0",
        marginBottom: theme.spacing.xs,
    },


    highlight: {

    },

    description: {
        textAlign: 'center',

        '@media (max-width: 520px)': {
            textAlign: 'left',
            fontSize: theme.fontSizes.md,
        },
    },

    gridHeader: {
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 20,
        fontSize: theme.fontSizes.xs,
    },

    mainView: {
        position: 'relative',
        paddingTop: 120,
        paddingBottom: 80,

        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    divider: {
        paddingTop: "50px",
        paddingBottom: "50px"
    },

    dividerTop: {
        paddingTop: "5px",
        paddingBottom: "5px"
    },

    appItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: theme.radius.md,
        height: 90,
        width: 150,
        backgroundColor: '#f5f0f0',
        border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
            }`,
        transition: 'box-shadow 150ms ease, transform 100ms ease',

        '&:hover': {
            boxShadow: `${theme.shadows.md} !important`,
            transform: 'scale(1.05)',
        },
    },
}));


const capitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
;

const onPageChanged = ({ state, setState, page }) => {
    setState({ ...state, page });
    const topElement = document.getElementById('discover_bots');
    window.scrollTo({ top: topElement?.offsetTop ?? 0, behavior: 'smooth' });
}

const groupBy = (arr, key) => {
    return arr.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const convertIntoCategoriesArr = (strArr) => {
    var resultArr = [];

    var obj = {};
    obj["value"] = "All"
    obj["label"] = "All"
    obj["key"] = "All"
    resultArr.push(obj)
   
    for (const element of strArr){
        var obj = {};
        obj["value"] = element
        obj["label"] = element
        obj["key"] = element
        resultArr.push(obj)
    };

    return resultArr;

};

export async function listBots(bots, sorting) {
    const url = config.ApiURL;

    var endpoint = "botstore.published.list";

    if(sorting === "trending") {
        endpoint = "botstore.trending.list";
    } else if(sorting === "latest_updated") {
        endpoint = "botstore.latestupdated.list";
    }

    try {
        const resp = await axios.get(`${url}/v2/${endpoint}`, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!resp.data.ok) {
            return;
        }

        bots = resp.data.published_bots;

        return bots;

    } catch (err) {
        console.error(err);
    }

    return null;
}

export async function getCategories() {
    const url = config.ApiURL;

    try {

        const resp = await axios.get(`${url}/v2/botstore.categories.list`, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        });

        /*
        if (!resp.data.ok) {
            return;
        }
        */

        return resp.data.categories;

    } catch (err) {
        console.error(err);
    }

    return null;
}

export function Store({ title, sorting }) {

    const { classes } = useStyles();
    const search = '';

    const initialState = () => {
        return {
            page: 1,
            applications: [],
            categories: [],
            bots: [],
            search: '',
            filter: 'All',
            level: '0',
        }
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        const fecthData = async () => {
          try {
            const respListBots = await listBots({}, sorting);
            const respCategories = await getCategories();
            setState({ ...state, bots: Object.values(respListBots), categories: Object.values(respCategories) });
          } catch (error) {
            console.log(error);
          }
        };
        fecthData();
      }, []);

    const doFilter = (bots) => {
        return bots.filter(t => {
            return (state.filter === 'All' || (state.filter != '' && t.category === state.filter));
        });
    }


    const _bots = doFilter(state.bots);

    const page = _bots.slice((state.page - 1) * config.BotsPerPage, state.page * config.BotsPerPage);

    return (

        <Container className={classes.wrapper} size={"%100"} p={0}>
            <Container size={1200} pl={20} pr={20}>
                <Container size={1200}>
                    <Group className={classes.gridHeader} position="apart">
                        <Group position="left" >
                            <Text size="xl" weight={700} mx="-10px" variant="gradient" gradient={{ from: colors.BackgroundColor, to: colors.BackgroundColor }} className={classes.hiddenMobile}>
                                {
                                    `${capitalize(title)} `
                                }
                            </Text>
                            <Text size="xl" weight={400} color="dimmed" mx="-4px" px={1} className={classes.hiddenMobile}>
                                (
                       {         
                                    state.bots.length
                                }
                                )
                            </Text>
                        </Group>
                        <Group position="center" spacing={"xs"} p={0} m={0}>
                            { 
                            sorting === "trending" ?
                            <Button radius="lg" size="md" onClick={() => { window.location.href = "/trending" }} variant="outline" color="dark" p={10} mr={5}> Trending Apps </Button>
                            :
                            <Button radius="lg" size="md" onClick={() => { window.location.href = "/trending" }} variant="gradient" gradient={{ from: colors.BackgroundColor, to: colors.BackgroundColor }} p={10} mr={5}> Trending Apps </Button>
                            }
                            { 
                            sorting === "latest_updated" ?
                            <Button onClick={() => { window.location.href = "/latest_updated" }} variant="outline" color="dark" radius="lg" size="md" p={10} m={10}> Latest Updated </Button>
                            :
                            <Button radius="lg" size="md" onClick={() => { window.location.href = "/latest_updated" }} variant="gradient" gradient={{ from: colors.BackgroundColor, to: colors.BackgroundColor }} p={10} mr={5}> Latest Updated </Button>
                            }
                                                        { 
                            sorting === "latest" ?
                            <Button onClick={() => { window.location.href = "/latest" }} variant="outline" color="dark" radius="lg" size="md" p={10} m={10}> Latest Released </Button>
                            :
                            <Button  onClick={() => { window.location.href = "/latest" }} variant="gradient"  gradient={{ from: colors.BackgroundColor, to: colors.BackgroundColor }} radius="lg" size="md" p={10} ml={5}> Latest Released </Button>
                            }
                        </Group>
                        <Group position='right' >
                            <Select
                                className={classes.hiddenMobile}
                                placeholder="Filter"
                                size="md"
                                defaultValue={state.filter}
                                onChange={(value) => setState({ ...state, filter: value })}
                                data={convertIntoCategoriesArr(state.categories)}
                            />
                        </Group>
                    </Group>
                </Container>
                <Divider pt={20} variant="dotted" className={classes.dividerTop} />
                <Center>
                    <SimpleGrid id='discover_bots' cols={4} spacing={30} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }, { maxWidth: 'lg', cols: 3 }]}>
                        {
                            page.length > 0 ?
                                page.map(bot => {
                                    const description = bot.summary.length > 100 ?
                                        bot.summary.substring(0, 100) + '...' :
                                        bot.summary;
                                    return (
                                        <StoreCard
                                            id={bot.id}
                                            key={bot.id}
                                            directory={bot.directory}
                                            level={bot.level}
                                            price={bot.price}
                                            release_date={bot.release_date}
                                            applications={bot.applications}
                                            name={bot.name}
                                            category={bot.category}
                                            description={description}
                                            summary={bot.summary}
                                            publisher={bot.author}
                                            icon={bot.icon}
                                            inLibrary={bot.inLibrary}
                                            iconColor={bot.color}
                                            execution={bot.execution}
                                            users={bot.users}
                                            isNew={bot.isNew}
                                            isUpcoming={bot.isUpcoming}
                                            slug={bot.slug}
                                            featured={bot.featured}
                                            star={Boolean(bot.star)}
                                            stars={bot.stars ?? 0}
                                            imports={bot.imports ?? 0}
                                        />
                                    );
                                }) :
                                <>
                                    <div style={{ minWidth: 320 }} className="text-md font-semibold leading-normal header ml-2 my-auto mt-1">No apps found.</div>
                                </>
                        }
                    </SimpleGrid>
                </Center>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30, marginBottom: 30 }}>
                    <Pagination page={state.page}
                        onChange={(page) => onPageChanged({ state, setState, page })}
                        total={Math.ceil(state.bots.length / 15)} />
                </div>
                <Divider py={50} variant="dotted" className={classes.dividerTop} />

            </Container>
        </Container>

    );
}

export default Store;
