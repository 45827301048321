import Header from './components/shared/Header'
import Footer from './components/shared/Footer'
import Home from './components/home/Index'
import Bot from './components/bots/Index'
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useRoutes
} from "react-router-dom";
import './App.css';

const AppRouter = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/:sort", element: <Home /> },
    { path: "/apps/:slug", element: <Bot /> },
    // ...
  ]);
  return routes;
};


function App() {
  return (
    <>
      <Header />
        <Router>
          <AppRouter />
        </Router>
      <Footer />
    </>
  );
}

export default App;
