import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles, Paper, Text, Title, useMantineTheme, Container } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    height: 320,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.2,
    fontSize: 44,
    marginTop: theme.spacing.xs,
    maxWidth: 500,
  },

  description: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 300,
    lineHeight: 1.2,
    fontSize: 28,
    marginTop: theme.spacing.xs,
  },

  category: {
    opacity: 0.7,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}));

function Card(image, title, category, description, color) {
  const { classes } = useStyles();

  return (
    <Paper p="xl" m="xl" shadow="sm" radius="lg" sx={{ backgroundImage: `url(${image})` }} className={classes.card}>
      <div>
        <Text className={classes.category} size="xs" color={color}>
          {category}
        </Text>
        <Title order={3} className={classes.title} color={color}>
          {title}
        </Title>
        <Title order={5} className={classes.description} color={color}>
          {description}
        </Title>
      </div>
    </Paper>
  );
}

const data = [
  {
    image:
      '/images/careousel/BotStore_1.png',
    title: 'Robomotion App Store Is Here!',
    description: 'Kick start your automation journey right now!',
    category: 'APP STORE',
    color: "white"
  },
  {
    image:
      '/images/careousel/BotStore_2.png',
    title: 'Apps from Robomotion',
    description: 'Run your apps on cloud right now!',
    category: 'Robomotion Originals',
    color: "gray.7"
  },
/*  {
    image:
      '/images/careousel/BotStore_3.png',
    title: 'CRYPTO APPS',
    description: 'BackTesting, Paper Trading, Trending Tracking and More!',
    category: 'Robomotion Originals',
    color: "gray.7"
  }, */
  {
    image:
      '/images/careousel/BotStore_4.png',
    title: 'Are you an RPA Developer?',
    description: 'Start earning money on Robomotion App Store.',
    category: 'RPA Developers',
    color: "gray.7"
  },
  {
    image:
      '/images/careousel/BotStore_5.png',
    title: 'Robomotion Affiliate Partnership',
    description: 'Join Robomotion Affiliate Program Now!',
    category: 'Affiliate Program',
    color: "gray.7"
  },
/*  {
    image:
      '/images/careousel/BotStore_6.png',
    title: 'Need Job Postings?',
    description: 'Test Out our Job Posting Scraper Apps!',
    category: 'JOB SCRAPER',
    color: "gray.7"
  },*/
];


const CardsCarousel = ({ }) => {

  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const slides = data.map((item) => (
    <Carousel.Slide key={item.title}>
      {Card(item.image, item.title, item.category, item.description, item.color)}
    </Carousel.Slide>
  ));

  return (
    <Container size={1200} pl={20} pr={20} pt={20}  >
      <Carousel
        slideSize="100%"
        breakpoints={[{ maxWidth: 'sm', slideSize: '100%', slideGap: 1 }]}
        align="start"
        slidesToScroll={mobile ? 1 : 1}
      >
        {slides}
      </Carousel>
    </Container>

  );
}

export default CardsCarousel;