const config = {
    ApiURL: "https://api.robomotion.io",
    AppURL: "https://api.robomotion.io/v1/apps.bot.insert",
    ImagesRootURL: "/images",
    BotApplicationIconURL: "/images/applications",
    BotsPerPage: 16,
    BotsURL: "https://raw.githubusercontent.com/robomotionio/robomotion-bots/master/bots.json",
    BotsRootURL: "https://raw.githubusercontent.com/robomotionio/robomotion-bots/master/bots",
    BotImagesRootURL: "/images/bots",
    BotScreenshotsRootURL: "/images/screenshots",
    BotsIconRootURL: "/images/icons",
};

export default config;
