import {
    createStyles,
    Title,
    Text,
    Container,
    Center,
    SimpleGrid,
} from '@mantine/core';

import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: "auto",
        paddingTop: 50,
        backgroundColor: "#F8F6F4",


        '@media (max-width: 755px)': {
            paddingTop: 50,
            paddingBottom: 50,
        },
    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 50,
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "26px",
        color: "#101D24BB",
        marginBottom: theme.spacing.sm,
        fontFamily: 'Inter',

        [theme.fn.smallerThan('lg')]: {
            fontSize: 18,
        },
    },

    description: {
        /*color: "#325170",*/
        color: "#1D2D35AA",
        textAlign: 'center',
        fontSize: 20,
        maxWidth: "600px",
    },

    highlight: {
        fontWeight: 800,

    },
    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    subDescription: {
        marginTop: "40px",
        textAlign: 'center',
        fontSize: 16,
    },


    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

}));

const HowItworks = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={"%100"}>
            <div className={classes.hero}>
                <Title className={classes.title}>
                    How Does It Work?
                </Title>

                <Container px={30} size={1100}>
                    <Center>
                        <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }, { maxWidth: 'md', cols: 2 }]}>

                            <Text size="xl" className={classes.description} p={20}>
                                <b><Text component="span"
                                    className={classes.highlight}
                                    inherit
                                    variant="gradient"
                                    gradient={{ from: colors.PrimaryColor, to: colors.SecondaryColor }}
                                >
                                    <b></b></Text> Create & Sell{' '}</b>
                                    App Store is a marketplace for RPA Developers where they can create & sell ready to use solutions to the global customers.
                            </Text>
                            <Text size="xl" className={classes.description} p={20}>
                                <b><Text component="span"
                                    className={classes.highlight}
                                    inherit
                                    variant="gradient"
                                    gradient={{ from: colors.PrimaryColor, to: colors.SecondaryColor }}
                                >
                                    <b></b></Text> Discover{' '}</b>
                                     what can be automated from experienced global RPA Developers. 
                            </Text>

                            <Text size="xl" className={classes.description} p={20}>
                                <b><Text component="span"
                                    className={classes.highlight}
                                    inherit
                                    variant="gradient"
                                    gradient={{ from: colors.PrimaryColor, to: colors.SecondaryColor }}
                                >
                                    <b></b></Text>Save{' '}</b>
                                     huge amount of time using ready-to-use solutions for your daily-basis tasks.
                            </Text>
                        </SimpleGrid>
                    </Center>
                </Container>

            </div>
        </Container>

    );
}

export default HowItworks;
