import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles, Divider, Paper, useMantineTheme } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.2,
    fontSize: 44,
    marginTop: theme.spacing.xs,
    maxWidth: 500,
  },

  description: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 300,
    lineHeight: 1.2,
    fontSize: 28,
    marginTop: theme.spacing.xs,
  },

  category: {
    opacity: 0.7,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}));

function Card( image) {
  const { classes } = useStyles();

  return (
    <Paper p="xl" m={4} radius="md" sx={{ backgroundImage: `url(${image})` }} className={classes.card}>
      <div>
       
      </div>
    </Paper>
  );
}

const data = [
  {
    image:
    '/images/screenshots/robomotion-crypto/1.png',  
  },
  {
    image:
    '/images/screenshots/robomotion-crypto/2.png',  
  },
  {
    image:
    '/images/screenshots/robomotion-crypto/3.png',  
  },
  {
    image:
    '/images/screenshots/robomotion-crypto/4.png',  
  },
  {
    image:
    '/images/screenshots/robomotion-crypto/5.png',  
  },
  {
    image:
    '/images/screenshots/robomotion-crypto/1.png',  
  },
];

const ImagesCarousel = ({ screenshots }) => {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  if(!screenshots) {
    return (
      <></>
    );
  }

  var ssData = [];

  for(var i = 0; i < screenshots.length; i++) {
    ssData.push({"image":screenshots[i]})
  };

  console.log(ssData);

  const slides = ssData.map((item) => (
    <Carousel.Slide key={item.image}>
      {Card(item.image)}
    </Carousel.Slide>
  ));

  return (
    <>
      <Carousel
        slideSize="25%"
        breakpoints={[{ maxWidth: 'sm', slideSize: '100%', slideGap: 1 }]}
        align="start"
        slidesToScroll={mobile ? 1 : 1}
      >
        {slides}
      </Carousel>
      <Divider my="sm" variant="dotted" />
    </>

  );
}

export default ImagesCarousel;