import {
	createStyles,
	Text,
	Button,
	Badge,
	Group,
	Indicator,
	Card,
	ThemeIcon,
	Image,
} from '@mantine/core';
import config from '../../const/config';
import colors from '../../const/colors';

const useStyles = createStyles((theme) => ({

	card: {
		backgroundColor: "#FFFFFF77",
		border: "none",
		'&:hover': {
			backgroundColor: "#FFFFFF",
			boxShadow: theme.shadows.sm,
		},
		cursor: 'pointer',
		minWidth: "280px",
		maxWidth: "280px",
	},

	imageSection: {
		padding: theme.spacing.lg,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
			}`,
	},

	label: {
		marginBottom: theme.spacing.xs,
		lineHeight: 1,
		fontWeight: 700,
		fontSize: theme.fontSizes.xs,
		letterSpacing: "-0.25",
		textTransform: 'uppercase',
	},

	section: {
		padding: theme.spacing.md,
		borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
			}`,
	},

	icon: {
		border: "2px solid #FFFFFF",
		boxShadow: theme.shadows.xs,

	},

	tinyIcon: {
		marginRight: "5px",
		color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
	},

	description: {
		marginTop: "-20px !important",
	},

	price: {
		marginTop: "-15px !important",
	},

	title: {
		fontFamily: 'Barlow, sans-serif',
		fontWeight: '600',
		color: "#325170F0",
		paddingTop: "0px !important",
		marginTop: "-10px !important",
	},

	body: {
		minHeight: "80px"
	},


	hiddenMobile: {
		[theme.fn.smallerThan('md')]: {
			display: 'none',
		},
	},

	hiddenDesktop: {
		[theme.fn.largerThan('md')]: {
			display: 'none',
		},
	},

	footer: {
		padding: `${theme.spacing.xs}px ${theme.spacing.lg}px`,
		marginTop: theme.spacing.md,
		borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
			}`,
	},
}));


function GetFeatured(isFeatured) {
	if (isFeatured) {
		return (
			<Badge color="yellow" variant="outline">Featured</Badge>
		);
	}
	else {
		return (
			<></>
		);
	}

}


function GetBadge(level) {
	if (level === 1) {
		return (
			<Badge variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}>Beginner</Badge>
		);
	}
	else if (level === 2) {
		return (
			<Badge variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}>Intermediate</Badge>
		);
	}
	else if (level === 3) {
		return (
			<Badge variant="gradient" gradient={{ from: 'orange', to: 'red' }}>Advanced</Badge>
		);
	}
}

function GetCoverImage(directory) {
	return `${config.BotImagesRootURL}/${directory}`
}

function GetIcon(directory) {
	return `${config.BotsIconRootURL}/${directory}`
}

function GetPrice(price) {
	if (price !== 0) {
		return (
			<>
				<Text fz="xl" fw={700} sx={{ lineHeight: 1 }} color={"gray.7"}>
					{price}
				</Text>
				<Text fz="sm" c="dimmed" fw={500} sx={{ lineHeight: 1 }} mt={3}>
					per month
				</Text>
			</>
		);
	}
	else {
		return (
			<>
				<Text fz="xl" fw={700} sx={{ lineHeight: 1 }} color={"gray.7"}>
					Free
				</Text>

			</>
		);
	}
}

function GetButton(price, inLibrary, release_date) {

    var date = Date.parse(release_date);

	if(date < Date.now()) {
		if (inLibrary) {
			return (
				<>
					<Button radius="md" style={{ flex: 1, fontSize:16  }} variant="outline" color="dark">
						In Library
					</Button>
				</>
			);
		}
		else if (price !== 0) {
			return (
				<>
					<Button radius="md" style={{ flex: 1, fontSize:16  }} variant="gradient"  gradient={{ from: colors.BackgroundColor, to: colors.BackgroundColor }}>
						Buy
					</Button>
				</>
			);
		} else {
			return (
				<>
					<Button radius="md" style={{ flex: 1, fontSize:16 }} variant="gradient"  gradient={{ from: colors.BackgroundColor, to: colors.BackgroundColor }}>
						Get
					</Button>
				</>
			);
		}
	} else {
		return (
			<>
				<Button radius="md" style={{ flex: 1, fontSize:16  }} variant="gradient"  gradient={{ from: colors.BackgroundColor, to: colors.BackgroundColor }}>
					Upcoming
				</Button>
			</>
		);
	}
}

function GetLogo(isNew, isUpcoming, directory, slug, classes, url) {
	if (isNew) {
		return (
			<>
				<Indicator inline label="NEW" size={20} color="lime" fw={500} >
					<ThemeIcon radius={"25px"} size={"120px"} px={-5} mx={0} color={"white"} className={classes.icon}>
						<Image fit radius={"24px"} src={url} alt={slug} />
					</ThemeIcon>
				</Indicator>
			</>
		);
	}
	else if (isUpcoming) {
		return (
			<>
				<Indicator inline label="UPCOMING" size={22} color="orange" fw={500} >
					<ThemeIcon radius={"25px"} size={"120px"} px={-5} mx={0} color={"white"} className={classes.icon}>
						<Image fit radius={"24px"} src={url} alt={slug} />
					</ThemeIcon>
				</Indicator>
			</>
		);
	} else {
		return (
			<>
				<ThemeIcon radius={"25px"} size={"120px"} px={-5} mx={0} color={"white"} className={classes.icon}>
					<Image fit radius={"24px"} src={url} alt={slug} />
				</ThemeIcon>
			</>
		);
	}
}



const StoreCard = ({ id, icon, level, isNew, release_date, isUpcoming, inLibrary, execution, users, publisher, price, summary, directory, category, applications, iconColor, name, description, slug, featured = false, stars = 0, imports = 0, star = false }) => {

	const { classes, theme } = useStyles();

	return (

		<Card withBorder radius="md" component="a" shadow="xs" className={classes.card} href={`/apps/${slug}?utm_source=appstore&utm_medium=cpc&utm_campaign=${slug}`}>
			<Card.Section className={classes.imageSection}>
				<Group spacing="md">
					{
						GetLogo(isNew, isUpcoming, directory, slug, classes, icon)
					}
				</Group>

			</Card.Section>

			<Text fw={700} size={20} color={"gray.7"} pt={10}>{name}</Text>

			<div>
				<Group spacing="md" position="apart" grow>
					<Text fz="sm" c="dimmed">
						{category}
					</Text>
					<Text fz="sm" c="orange" align='right'>
						{publisher}
					</Text>
				</Group>
			</div>

			<Card.Section className={classes.section} mt="md">
				<Text fz="xs" c="dimmed" >
					{summary}
				</Text>
			</Card.Section>
			<Card.Section className={classes.section}>
				<Group spacing={60}>
					<div>
						{GetPrice(price)}
					</div>

					{GetButton(price, inLibrary, release_date)}
				</Group>
			</Card.Section>
		</Card>
	);
}

export default StoreCard;
