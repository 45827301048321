import {
    createStyles,
    Group,
    Container,
    Card,
    Center,
    Title,
    Text,
    Image,
    Badge,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: "auto",
        paddingTop: 40,
        backgroundColor: "#FFFFFF",

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 80,
    },

    title: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: "3.4em",
        color: "#F8F6F4",
        fontFamily: 'Inter',

        [theme.fn.smallerThan('lg')]: {
            fontSize: "2.6em",
        },
    },

    topTitle: {
//        fontWeight: 500,
        fontSize: "18px",
        textAlign: "center",
        fontFamily: 'Inter',
        fontWeight: "600",
        color: "#F2724A",
        textTransform: "uppercase"
    },

    bottomTitle: {
        paddingTop: 20,
        textAlign: 'center',
        fontWeight: 800,
        fontSize: "20px",
        fontFamily: 'Inter'
    },

    description: {
        /*color: "#325170",*/
        color: "#325170",
        textAlign: 'center',
        fontSize: 20,
        maxWidth: "800px",
    },

    listItem: {
        /*color: "#325170",*/
        color: "#1D2D35",
        textAlign: 'center',
        fontSize: 20,
        maxWidth: "600px",
    },

    highlight: {
        fontWeight: 800,
    },
    underlined: {
        textDecoration: "underline wavy #2196F3"
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    infoCard: {
        backgroundColor: "#FFFFFF00",
    },

    imageCard: {
        backgroundColor: "#F8F6F400",
    },

    discord: {
        backgroundColor: "#F8F6F400",
    },

    imageCardChild: {
        backgroundColor: "#F8F6F400",
    },

    badge: {
        color: "#325170"
    }
}));

const Discord = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size={"100%"}>
            <div className={classes.hero}>
                <Center>
                    <Card className={classes.discord} radius="lg">
                        <Card className={classes.infoCard}>
                            <Text className={classes.topTitle}>
                                Community
                            </Text>
                            <Title className={classes.title}>
                                <Text component="span"
                                    className={classes.highlight}
                                    inherit
                                    color={"#325170"}
                                >
                                    Our Discord </Text>

                            </Title>
                            <Title className={classes.title}>
                                <Text component="span"
                                    className={classes.highlight}
                                    inherit
                                    color={"#325170"}
                                >
                                    Community Is Growing </Text>

                            </Title>

                            <Text className={classes.description} pt={20} pb={20}>
                            Join our community of <b>3500+</b> members. Gain access to our supportive and responsive community, along with our dedicated team, who are always available to provide prompt assistance whenever you need it.
                            </Text>
                            <Container className={classes.wrapper} size={"700px"} pt={25}>
                                <Group pt={0} position={"center"}>
                                    <Badge variant="outline" color={"white"} size="xl" radius="md" className={classes.badge}>
                                        Share Your Experiences
                                    </Badge>
                                    <Badge variant="outline" color={"white"} size="xl" radius="md" className={classes.badge}>
                                        Meet with the Team
                                    </Badge>
                                    <Badge variant="outline" color={"white"} size="xl" radius="md" className={classes.badge}>
                                        Join Challenges
                                    </Badge>
                                </Group>
                            </Container>
                            <Container className={classes.wrapper} size={"300px"} pt={50}>
                                <a href="https://community.robomotion.io">
                                <Image src="/images/discord_trans.png" ></Image>
                                </a>
                            </Container>
                        </Card>
                    </Card>
                </Center>
            </div>
        </Container>

    );
}

export default Discord;
