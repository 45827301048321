import { useEffect, useState } from 'react';
import config from '../../const/config';
import { getProfile } from '../../const/cookie';
import axios from 'axios';
import ApplicationIcon from '../shared/Applications'

import {
    createStyles,
    Text,
    Alert,
    Button,
    Navbar,
    Container,
    Breadcrumbs,
    Divider,
    Anchor,
    Badge,
    Group,
    ActionIcon,
    Card,
    Image,
    Paper,
    Flex,
    ThemeIcon,
    Timeline,
    SimpleGrid,
    Tooltip,
} from '@mantine/core';
import {
    IconAlertCircle,
    IconHeart,
    IconShare,
    IconCircleDot,
    IconCopy,
    IconCheck
} from '@tabler/icons';
import ImagesCarousel from './Images';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import { useClipboard } from '@mantine/hooks';
import colors from '../../const/colors';

const TimelineText = (props) => <Text color="dimmed" size="md">{props.children}</Text>
const TimelineExtra = (props) => <Text size="sm" mt={4}>{props.children}</Text>
const Mdli = (props) => (
    <li>
        <TimelineText>{props.children}</TimelineText>
    </li>
);
const MdCode = (props) => {
    const { styles, theme } = useStyles();
    const clipboard = useClipboard({ timeout: 1000 });

    return (
        <div style={{ width: '100%' }}>
            <div style={{
                width: '100%', backgroundColor: '#3e3e42', color: '#ddd',
                padding: 8, display: 'flex', flex: 1, columnGap: 8, justifyContent: 'space-between'
            }}>
                <code style={{ whiteSpace: 'pre-wrap' }}>
                    {props.children}
                </code>
                <div>
                    <Tooltip label={clipboard.copied ? 'Copied' : 'Copy to Clipboard'}>
                        <ActionIcon mx={5} variant='transparent' onClick={() => !clipboard.copied && clipboard.copy(props.children)}>
                            <IconCopy size={"md"} color={theme.colors.gray[4]} stroke={1.5}
                                style={{ transition: 'all 300ms ease-in-out', position: 'absolute' }}
                                strokeDasharray={50} strokeDashoffset={clipboard.copied ? -50 : 0} />
                            <IconCheck size={"md"} color={theme.colors.green[4]} stroke={1.5}
                                style={{ transition: 'all 300ms ease-in-out', position: 'absolute' }}
                                strokeDasharray={50} strokeDashoffset={clipboard.copied ? 0 : -50} />
                        </ActionIcon>
                    </Tooltip>
                </div>
            </div >
        </div>
    );
}

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: 120,
        zIndex: 0,


        '@media (max-width: 755px)': {
            paddingTop: 80,
            paddingBottom: 60,
        },
    },

    icon: {
        boxShadow: theme.shadows.sm,
        border: "0px solid #FFFFFF",

    },

    hero: {
        position: 'relative',
        zIndex: 1,
        paddingBottom: 60,
        paddingTop: 60,
    },

    highlight: {

    },

    title: {
        textAlign: 'center',
        fontWeight: 800,
        fontSize: 40,
        letterSpacing: -1,
        color: "#325170F0",
        marginBottom: theme.spacing.xs,
    },


    description: {
        textAlign: 'center',
        marginBottom: 80,

        '@media (max-width: 520px)': {
            textAlign: 'left',
            fontSize: theme.fontSizes.md,
        },
    },

    gridHeader: {
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 20,
        fontSize: theme.fontSizes.xl,
    },

    mainView: {
        justifyContent: "flex-start",
        alignContent: "flex-start",
        flexDirection: "row",
        [theme.fn.smallerThan('sm')]: {
            flexWrap: "wrap",
        },
    },

    navBarContent: {
        padding: "0px",
        width: "400px",
    },

    hiddenMobile: {
        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('md')]: {
            display: 'none',
        },
    },

    navbar: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: '#f5f0f000',
    },

    aside: {
        flex: `0 0 60px`,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRight: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
            }`,
    },

    tryButton: {
        width: "100%",
    },

    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },

    inputCard: {
        position: 'relative',
        padding: theme.spacing.xl,
        marginLeft: theme.spacing.xl,
        backgroundColor: '#f5f0f0',

    },

    authorCard: {
        position: 'relative',
        padding: theme.spacing.xl,
        marginLeft: 0,
    },

    stepsCard: {
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        transition: 'transform 150ms ease, box-shadow 100ms ease',
        padding: theme.spacing.xl,
        marginLeft: theme.spacing.xl,
        backgroundColor: '#f5f0f0',
    },

    section: {
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
            }`,
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        paddingBottom: theme.spacing.md,
    },

    like: {
        color: theme.colors.red[6],
    },

    label: {
        textTransform: 'uppercase',
        fontSize: theme.fontSizes.xs,
        fontWeight: 700,
    },
}));


const addBot = async (bot, state, setState) => {

    if(!isBotAvailable(bot)) {
        return;
    }

    setState({ ...state, adding: true });
    const profile = await getProfile();

    if (!Boolean(profile)) {
        window.location.assign('https://app.robomotion.io');
        return;
    }

    const url = config.ApiURL;
    const id = bot.id
    const req = { "app_id":id };

    axios.post(`${config.AppURL}`, req, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(resp => {
            if (resp.data && resp.data.ok === false && resp.data.error === "application_already_present") {
                window.location.assign('https://app.robomotion.io/bots');
                return;
            }
            const subdomain = resp.data.domain.split('.')[0];
            const id = resp.data.bot.id;
            const robomotion = "robomotion.io"//appUrl.split('.').slice(1, 3).join('.');
            const flowUrl = `https://${subdomain}.${robomotion}/bots/${id}/instances`;
            window.location.assign(flowUrl);
        })
        .catch(err => {
            window.location.assign('https://app.robomotion.io');
            return;
        });
}

function GetCover(directory) {
    return `${config.BotImagesRootURL}/${directory}`
}

function GetBadge(level) {
    if (level === 1) {
        return (
            <Badge variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}>Beginner</Badge>
        );
    }
    else if (level === 2) {
        return (
            <Badge variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}>Intermediate</Badge>
        );
    }
    else if (level === 3) {
        return (
            <Badge variant="gradient" gradient={{ from: 'orange', to: 'red' }}>Advanced</Badge>
        );
    }
}

export async function getBotInfo(slug) {

    const url = config.ApiURL;

    try {
        const urlWithID = `${url}/v2/botstore.published.get?slug=${slug}`;

        const resp = await axios.get(urlWithID, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!resp.data.ok) {
            return;
        }
        return resp.data.published_bot;

    } catch (err) {
        console.error(err);
    }

    return null;
}

function Warning(warning) {

    if (warning === undefined || warning === "" || warning === null) {
        return ""
    } else {
        return (
            <>
                <Divider my="sm" variant="dotted" />
                <Alert icon={<IconAlertCircle size={16} />} title="Warning" color="yellow" radius="md">
                    <ReactMarkdown children={warning} remarkPlugins={[remarkGfm]} components={{ p: Text }} />
                </Alert>
            </>
        );
    }
}

function Note(info) {
    if (info === undefined || info === "" || info === null) {
        return ""
    } else {
        return (
            <>
                <Divider my="sm" variant="dotted" />
                <Alert icon={<IconAlertCircle size={16} />} title="Info" radius="md">
                    <ReactMarkdown children={info} remarkPlugins={[remarkGfm]} components={{ p: Text }} />
                </Alert>
            </>
        );
    }
}

function Extra(extra) {
    if (extra === undefined || extra === "" || extra === null) {
        return ""
    } else if (extra instanceof Array) {
        return (
            <>
                <Divider my="sm" variant="dotted" />
                <Flex direction="column" rowGap={8}>
                    {extra.map(e => (
                        <Alert icon={<IconAlertCircle size={16} />} title="Note" radius="md">
                            <ReactMarkdown children={e} remarkPlugins={[remarkGfm]} components={{ p: Text }} />
                        </Alert>
                    ))}
                </Flex>
            </>
        );
    } else {
        return (
            <>
                <Divider my="sm" variant="dotted" />
                <Alert icon={<IconAlertCircle size={16} />} title="Note" radius="md">
                    <ReactMarkdown children={extra} remarkPlugins={[remarkGfm]} components={{ p: Text }} />
                </Alert>
            </>
        );
    }
}


const onRate = async (e, state, setState, bot) => {
    e.stopPropagation();
    e.preventDefault();

    if (state.starInProgress) {
        return;
    }

    const profile = await getProfile();
    if (!Boolean(profile)) {
        window.location.assign('https://app.robomotion.io');
        return;
    }

    setState({ ...state, starInProgress: true });

    const url = config.ApiURL;
    const slug = bot.slug;
    const id = slug.replaceAll('-', '_');
    const req = { id };

    axios.post(`${url}/v1/bots.star`, req, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        }

    }).then(resp => {
        if (resp.data.ok) {
            setState({
                ...state,
                starInProgress: false,
                bot: Object.assign({}, {
                    ...state.bot,
                    stars: resp.data.stars ?? 0,
                    star: resp.data.star
                }),
            })
        }

        setState({ ...state, starInProgress: false });

    }).catch(err => {
        console.error(err);
        setState({ ...state, starInProgress: false })
    });
}

function GetIcon(directory) {
    return `${config.BotsIconRootURL}/${directory}`
}


function GetApplications(bot) {

    if (!bot || !bot.applications) {
        return ("");
    }

    const applications = bot.applications.map((application) => {
        const appIcon = ApplicationIcon(application)
        if (appIcon.type === "tabler") {
            return (
                <Group key={appIcon.label} my={"sm"}>
                    <ThemeIcon color={appIcon.color} radius={"52px"} size={"52px"} /*variant={"light"}*/ px={0} mx={0}>
                        <appIcon.icon size={40} stroke={1} />
                    </ThemeIcon>
                    <Text size="lg" weight={400} >{appIcon.label}</Text>
                </Group>
            )
        }
        else {
            return (
                <Group key={appIcon.label} my={"sm"}>
                    <ThemeIcon color={appIcon.color} radius={"52px"} size={"52px"} /*variant={"light"}*/ px={0} mx={0}>
                        {appIcon.icon}
                    </ThemeIcon>
                    <Text size="lg" weight={400} >{appIcon.label}</Text>
                </Group>
            );
        }
    })

    return (applications)
}

function GetBotTags(bot) {

    if (!bot || !bot.keywords) {
        return ("");
    }

    const keywords = bot.keywords.map((tag) => {
        return (
            <Group key={tag}>
                <Badge color="grape">{tag}</Badge>
            </Group>
        )
    })

    return (keywords)
}

function camelize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function GetLanguages(bot) {

    if (!bot || !bot.supported_languages) {
        return ("");
    }

    const languages = bot.supported_languages.map((lang) => {
        return (
            <Group key={lang}>
                <Text span c="grape" weight={800} inherit>{camelize(lang)}</Text>
            </Group>
        )
    })

    return (languages);
}

function GetPlatforms(bot) {

    if (!bot || !bot.platforms) {
        return ("");
    }

    const platforms = bot.platforms.map((platform) => {
        return (
            <Group key={platform}>
                <Text span c="grape" weight={600} inherit>{camelize(platform)}</Text>
            </Group>
        )
    })

    return (platforms)
}

function GetBCItems(bot) {

    if (!bot || !bot.category) {
        return [];
    }

    const category = bot.category.toLowerCase();

    const bcItems = [
        { title: 'Apps', href: '/' },
        { title: bot.category, href: `/categories/${category.replaceAll(' ', '-')}/` },
    ].map((item, index) => (
        <Anchor href={item.href} key={index} color="grape">
            {item.title}
        </Anchor>
    ));

    return bcItems;

}

function GetVersions(bot) {

    if (!bot || !bot.versions) {
        return ("");
    }

    const reversed = [...bot.versions].reverse();
    const versions = reversed.map((item, i) => {
        return (
            <Timeline.Item key={"step" + i} size="md" bullet={<IconCircleDot size={16} color={"white"} />} title={item.name + " - " + item.created_at.split("T")[0]}>
                <ReactMarkdown children={item.description} remarkPlugins={[remarkGfm]} components={{ p: TimelineExtra }} />
            </Timeline.Item>
        )
    })


    return (
        <Timeline reverseActive bulletSize={24} lineWidth={3} >
            {versions}
        </Timeline>
    )
}

function GetPrice(price, pricing_period) {
	if (price !== 0) {
		return (
			<>
				<Text fz="xl" fw={700} sx={{ lineHeight: 1 }} color={"gray.7"}>
					{price}
				</Text>
				<Text fz="sm" c="dimmed" fw={500} sx={{ lineHeight: 1 }} mt={3}>
                    { pricing_period === 30 ? "Per Month" : "" }
                    { pricing_period === 360 ? "Per Year" : "" }
                    { pricing_period === 0 ? "One Time" : "" }
				</Text>
			</>
		);
	}
	else {
		return (
			<>
				<Text fz="xl" fw={700} sx={{ lineHeight: 1 }} color={"gray.7"}>
					Free
				</Text>

			</>
		);
	}
}

function getButtonInfo(bot) {

    var date = Date.parse(bot.release_date);

    if(date > Date.now())
        return "Upcoming App"

    if(date < Date.now() && bot.price > 0)
        return "Buy This App"

    if(date < Date.now() && bot.price === 0)
        return "Get This App"

    return "Upcoming App"

}

function isBotAvailable(bot) {

    var date = Date.parse(bot.release_date);

    if(date > Date.now())
        return false;

    return true

}

function isButtonDisabled(state, bot) {

    var date = Date.parse(bot.release_date);

    if(date > Date.now() && !state.adding)
        return false

    if(date < Date.now() && bot.price > 0 && !state.adding)
        return false

    if(date < Date.now() && bot.price === 0 && !state.adding)
        return false

    return true

}


const Bot = ({slug}) => {

    const { classes, theme } = useStyles();

    const initialState = () => {
        return {
            page: 1,
            bot: {},
            slug: slug,
            isLoaded: false,
            adding: false,
            starInProgress: false,
        }
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        const fecthData = async () => {
            try {
                const bot = await getBotInfo(state.slug);
                setState({ ...state, bot: bot, isLoaded: true });
            } catch (error) {
                console.log(error);
            }
        };
        fecthData();
    }, []);

    if (!state.isLoaded) {
        return ("");
    }
    else {
        return (
            <Container className={classes.wrapper} size={1400} pb={40}>
                <SimpleGrid id='discover_bots' cols={2} spacing={30} breakpoints={[{ maxWidth: 'lg', cols: 1 }, { maxWidth: 'xl', cols: 2 }]}>
                    <Navbar height={0} className={classes.navbar} p={0} fixed={false}>
                        <Navbar.Section className={classes.section}>
                            <Group noWrap spacing={20}>
                                <ThemeIcon radius={"25px"} size={"124px"} color={"white"} className={classes.icon}>
                                    <Image fit radius={"24px"} src={`${state.bot.icon}`} alt={state.bot.slug} />
                                </ThemeIcon>
                                <div className={classes.navBarContent}>
                                    <Group position={"apart"}>
                                        <Breadcrumbs separator="→" >{GetBCItems(state.bot)}</Breadcrumbs>
                                    </Group>
                                    <Text size="28px" weight={700} color="gray.7">{state.bot.name}</Text>
                                    <Text pb={5} size="md" >by <b><Text span c={colors.BackgroundColor} weight={800} inherit>{state.bot.publisher.name}</Text></b></Text>
                                    <Group position="apart" my={0}>
                                        <Text size="lg" weight={500} color="gray.8">{GetPrice(state.bot.price, state.bot.pricing_period)}</Text>
                                        <Badge variant="gradient" size="lg" gradient={{ from: 'orange', to: 'red' }}>{state.bot.versions[0].name}</Badge>
                                    </Group>
                                </div>
                            </Group>
                            <Group grow spacing={20} pt={20}>
                                <Button size="lg" my={"md"}  margin="auto" variant="gradient" gradient={{ from: colors.BackgroundColor, to: colors.BackgroundColor }}
                                    radius={"lg"}
                                    onClick={e => addBot(state.bot, state, setState)}
                                    className={classes.tryButton}
                                    disabled={isButtonDisabled(state, state.bot)}>
                                    {state.adding ? 'Adding...' : getButtonInfo(state.bot)}
                                </Button>
                                <Button size="lg" leftIcon={<IconHeart size="1rem" />} variant="outline" radius={"lg"} color={"dark"}>
                                    Add to Whislist
                                </Button>
                                <Button size="lg" leftIcon={<IconShare size="1rem" />} variant="outline" radius={"lg"} color={"dark"}>
                                    Share
                                </Button>
                            </Group>
                            <Divider my="sm" variant="dotted" />
                            <ImagesCarousel screenshots={state.bot.screenshots} />
                            <Group spacing="lg" pr={"sm"}>
                                {GetApplications(state.bot)}
                            </Group>
                            <Divider my="sm" variant="dotted" />
                            <Card.Section>
                                <Group>
                                    {GetBotTags(state.bot)}
                                </Group>
                            </Card.Section>
                            <Divider my="sm" variant="dotted" />
                            <Card.Section>
                                <Group>
                                    <Text py={5}>Languages: </Text>
                                    {GetLanguages(state.bot)}
                                </Group>
                            </Card.Section>
                            <Divider my="sm" variant="dotted" />
                            <Card.Section>
                                <Group>
                                    <Text py={5}>Platforms: </Text>
                                    {GetPlatforms(state.bot)}
                                </Group>
                            </Card.Section>
                            <Divider my="sm" variant="dotted" />
                            <Card.Section>
                                <Group>
                                    <Text py={5}>Release Date: </Text>
                                    {state.bot.release_date}
                                </Group>
                            </Card.Section>
                            {Warning(state.bot.warning)}
                            {Note(state.bot.note)}
                            {Extra(state.bot.extra)}
                            <Divider my="sm" variant="dotted" />
                            <Paper withBorder radius="md" className={classes.authorCard}>
                                <Text py={5} size="md" >Author: <Text span c="orange" weight={500} inherit>{state.bot.publisher.name}</Text></Text>
                                <Text py={5} size="md" >About: <Text span c="black" inherit>{state.bot.publisher.about}</Text></Text>
                                <Text py={5} size="md" >Contact: <Text span c="orange" inherit>{state.bot.support_email}</Text></Text>
                            </Paper>
                        </Navbar.Section>
                    </Navbar>

                    <SimpleGrid w="100%">
                        { 
                        state.bot.cover && state.bot.cover != "" 
                        ?
                        <Image px={"md"} fit radius={"md"} width={"%100"} src={`${state.bot.cover}`} alt={state.bot.slug} />
                        : 
                        <></>
                        }
                        <Text color="gray.7" pl={"md"} pr={"md"}>
                            <>
                                <ReactMarkdown children={state.bot.description} remarkPlugins={[remarkGfm]} components={{ p: TimelineExtra }} />
                            </>
                        </Text>
                        <Paper withBorder radius="md" className={classes.inputCard}>
                            <Text transform="" color="dimmed" size="24px" pb={20}>
                               Versions
                            </Text>
                            {GetVersions(state.bot)}
                        </Paper>
                    </SimpleGrid>
                </SimpleGrid>
            </Container >
        );
    }
}

export default Bot;