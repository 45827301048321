import Store from './Store';
import Careousel from './Careousel';
import Discord from '../shared/Discord';
import HowItworks from '../shared/HowItWorks';
import { useParams } from 'react-router-dom';

const Home = () => {
    const { sort } = useParams();


    var title='TRENDING APPS'
    var _sort="trending"
    
    if(sort === "") {
        title='TRENDING APPS'
        _sort = "trending"
    }
    else if(sort === "trending") {
        title='TRENDING APPS'
        _sort = sort
    }
    else if(sort === "latest_updated") {
        title='LATEST UPDATED'
        _sort = sort
    }
    else if(sort === "latest") {
        title='LATEST APPS'
        _sort = "latest"
    }

    return (
        <>
            <Careousel />
            <Store title={title} sorting={sort} />
            <HowItworks />
            <Discord />
        </>
    );
}

export default Home;