import Bot from './Bot';
import Discord from '../shared/Discord';
import { useParams } from 'react-router-dom';

const BotView = () => {
    const { slug } = useParams();

    return (
        <>
            <Bot slug={slug} />
            <Discord />
        </>
    );
}

export default BotView;